'use client'

import { useForm } from 'react-hook-form'

import { Link } from '@/components'
import { InputComponent as Input } from '@/components/input/main'
import { configs } from '@/constants'
import { useLoader, useMounted, useTranslate } from '@/hooks'
import { AuthService } from '@/services'
import type { FormLogin } from '@/types/form'
import { notice } from '@/utils/addon'

export function FormLoginComponent() {
  // __STATE's
  const loader = useLoader()

  const { t } = useTranslate()

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormLogin>()

  // __FUNCTION's
  const onSubmit = handleSubmit(async (data) => {
    loader.on()

    const response = await AuthService.signIn(data)
    if (response?.status) {
      notice.success('เข้าสู่ระบบสำเร็จ ✅', { title: 'Successfuly', duration: 1 })
      await AuthService.profile()
      location.assign(`${configs.APP_BASE_URL}/account`)
    } else {
      notice.warn(t(`Login.ErrorHandleLogin.${response.message}`), { duration: 4 })
      AuthService.signOut()
    }

    loader.off()
  })

  // __EFFECT's
  useMounted(() => {
    AuthService.signOut()
  })

  // __RENDER
  return (
    <form className='ui--guard-form' onSubmit={onSubmit}>
      <div className='grid gap-4'>
        <Input
          key='.phoneNo'
          icon='telephone'
          name='phone_number'
          placeholder='เบอร์โทรศัพท์'
          register={register}
          errors={errors.phone_number}
          rules={{
            required: { value: true, message: 'กรุณาหรอกเบอร์โทรศัพท์' },
            minLength: { value: 10, message: 'เบอร์โทรศัพท์ไม่ถูกต้อง' },
            maxLength: { value: 10, message: 'เบอร์โทรศัพท์ไม่ถูกต้อง' }
          }}
        />

        <Input
          key='.password'
          type='password'
          icon='lock'
          name='password'
          placeholder='รหัสผ่าน'
          register={register}
          errors={errors.password}
          rules={{
            required: true,
            minLength: { value: 8, message: 'รหัสผ่านจะต้องประกอบด้วย 8~32 ตัวอักษร' },
            maxLength: { value: 32, message: 'รหัสผ่านจะต้องประกอบด้วย 8~32 ตัวอักษร' }
          }}
        />
      </div>

      <div className='mt-10'>
        <button className='btn btn-gold btn-submit' type='submit'>
          <span className='text text-base font-bold'>เข้าสู่ระบบ</span>
        </button>

        <Link className='btn btn-text mt-4' href='/register' key='.register'>
          <span className='text text-base'>สมัครสมาชิก</span>
        </Link>
      </div>
    </form>
  )
}
